<template>
  <v-container fluid text-xs-center>
    <div>
      <v-card
        class="d-flex align-center justify-space-between pa-3 pa-sm-2"
        color="primarygrad"
      >
        <div class="d-flex align-center">
          <v-img
            src="../../assets/credit.svg"
            class="credit-img ma-0 mr-2"
          ></v-img>
          <p class="ma-0 custom-color">
            {{
              $store.getters.companyDetails.whatsappCredits &&
                $store.getters.companyDetails.whatsappCredits
            }}
          </p>
        </div>
        <v-btn
          to="/whatsapp-settings"
          rounded
          small
          outlined
          color="white"
          class="white--text"
        >
          <v-icon color="white">credit_card</v-icon>
          Buy Credits
        </v-btn>
      </v-card>
    </div>

    <v-layout row wrap style="margin: 0px">
      <v-flex xs12 sm12 md12 style="padding-bottom: 1%;padding-top:1%">
        <WhatsappConnector
          v-if="!isConnected && !whatsappRegistered"
          :is-connected="isConnected"
          :whatsapp-registered="whatsappRegistered"
          :set-chat-show="setChatShow"
        />
      </v-flex>

      <v-container
        v-if="!isConnected && !whatsappRegistered && !initialInboxDialog"
        fluid
        class="whiteBackGround"
      >
        <first-page
          :img-src="imgSrc"
          :dialog-name.sync="initialInboxDialog"
          :points="points"
          :headline="headline"
        ></first-page>
      </v-container>
      <WhatsappChat
        v-if="isConnected && whatsappRegistered"
        :send-message="sendMessage"
        :wapp-access="userDetails.accessToken"
        :godial-user="user"
      />
    </v-layout>
  </v-container>
</template>
<script>
import axios from "axios";
// import { mdiAlertBox } from "@mdi/js";
import { mapGetters } from "vuex";
import WhatsappChat from "@/components/whatsappChat";
import WhatsappConnector from "../../components/whatsappConnector.vue";
import firstPage from "@/components/firstPage.vue";
export default {
  components: {
    WhatsappChat,
    WhatsappConnector,
    firstPage,
  },
  data() {
    return {
      initialInboxDialog: false,
      isConnected: false,
      whatsappRegistered: false,
      userDetails: {}, //To store whatsapp user details for authentication
      phoneNoId: "",
      sendMessageTo: "",
      businessId: "",
      message: "",
      headline: "Simplify Customer Support with Shared inbox",
      points: [
        "Centralized Command Center: See all your team's customer chats in one convenient dashboard.",
        "Targeted Communication: Select specific contacts from a pre-defined list to send personalized messages directly from the inbox.",
        "Actionable Insights: Gain valuable oversight into team communication and customer trends, allowing you to refine your WhatsApp strategy.",
      ],
      // icons: {
      //     mdiAlertBox,
      // },
      goUser: {},
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "companyDetails", "selectedTeam"]),
    imgSrc() {
      return require("@/assets/goinbox.svg");
    },
  },
  async mounted() {
    // Look for integration details
    const getIntegration = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
    );
    if (
      getIntegration.body.length &&
      getIntegration.body[0].config.initializeDone
    ) {
      this.setChatShow();
    }
  },
  methods: {
    // checkLoginStatus() {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       const response = await new Promise(FB.getLoginStatus);

    //       if (response.status === "connected") {
    //         this.isConnected = true;
    //       }
    //       console.log("User: ", response);
    //       this.userDetails = response.authResponse;
    //       // Getting integration details to check if integration details already exists
    //       const getIntegration = await axios.get(
    //         `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
    //       );
    //       console.log("getIntegration response: ", getIntegration.data);

    //       if (getIntegration.data.length) {
    //         const data = {
    //           config: {
    //             ...getIntegration.data.config,
    //             tempAccessToken: this.userDetails,
    //             tokenGenerateTime: new Date(),
    //           },
    //         };
    //         const updateIntegration = await axios.patch(
    //           `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`,
    //           data
    //         );
    //         console.log("Updated Integration data: ", updateIntegration.data);
    //       }

    //       resolve(response);
    //     } catch (error) {
    //       console.error("Error in checkLoginStatus:", error);
    //       reject(error);
    //     }
    //   });
    // },

    // To send template messages to whatsapp
    async sendMessage(accessToken, contactNumber) {
      try {
        const getUser = await await axios.get(
          `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
        );

        const data = {
          messaging_product: "whatsapp",
          to: contactNumber,
          type: "template",
          template: {
            name: "hello_world",
            language: {
              code: "en_US",
            },
          },
        };
        const response = await axios.post(
          `https://graph.facebook.com/v18.0/${getUser.data[0].config.whatsappNoId}/messages`,
          data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.messages.length) {
          this.$swal({
            type: "success",
            text: "Message sent successfully",
          });
        }
      } catch (e) {
        console.log("send messge error: ", e);
        this.$swal({
          type: "error",
          text: e.response.data.error.message,
        });
      }
    },

    // Send text msg on whatsapp
    // async sendTextMessage(accessToken, contactNumber, message) {
    //   // console.log("Send message fired");
    //   // console.log("access Token: ", accessToken);
    //   //   const contactNumber = this.sendMessageTo;
    //   const getUser = await axios.get(
    //     `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
    //   );
    //   const data = {
    //     recipient_type: "individual",
    //     to: contactNumber,
    //     type: "text",
    //     text: {
    //       body: message,
    //     },
    //   };
    //   console.log("data sending: ", data);
    //   // const headers = [`Authorization: Bearer ${accessToken}`, 'Content-Type: application/json'];
    //   const response = await axios.post(
    //     `https://graph.facebook.com/v18.0/${getUser.data[0].config.whatsappNoId}/v1/messages`,
    //     data,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );

    //   if (response.data.messages.length) {
    //     this.$swal({
    //       type: "success",
    //       text: "Message sent successfully",
    //     });
    //   } else {
    //     this.$swal({
    //       type: "error",
    //       text: "An error occurred while sending message. Please try again!",
    //     });
    //   }
    //   console.log("Message response: ", response);
    // },
    // async saveWhatsappDetails() {
    //     console.log("server endpoint: ", this.ENDPOINT);
    //     console.log("user: ", this.user);
    //     const data = {
    //         whatsappNoId: this.phoneNoId,
    //         whatsappBusinessId: this.businessId
    //     }
    //     try {
    //         const resp = await axios.patch(`${this.ENDPOINT}/accounts/${this.user.id}?access_token=${this.user.token}`, data);
    //         console.log("Response from account: ", resp.data);
    //         if (resp.data) this.whatsappRegistered = true;
    //     } catch (error) {
    //         console.log("Error: ", error);
    //         this.$swal({
    //             type: "error",
    //             text: "An error occurred. Please try again!",
    //         })
    //     }

    // },
    // async saveWhatsappDetails() {
    //   console.log("server endpoint: ", this.ENDPOINT);
    //   console.log("user: ", this.user);
    //   // console.log("accessToken: ", this.userDetails);

    //   const data = {
    //     type: "whatsapp",
    //     enabled: true,
    //     config: {
    //       whatsappNoId: this.phoneNoId,
    //       whatsappBusinessId: this.businessId,
    //       whatsappMessageTemplates: ["hello_world"],
    //       tempAccessToken: this.userDetails.authResponse,
    //       tokenGenerateTime: new Date(),
    //     },
    //     companyId: this.user.companyId,
    //   };
    //   try {
    //     const resp = await axios.post(
    //       `${this.ENDPOINT}/integrations?access_token=${this.user.token}`,
    //       data
    //     );
    //     console.log("Response from account: ", resp.data);
    //     if (resp.data) this.whatsappRegistered = true;
    //   } catch (error) {
    //     console.log("Error: ", error);
    //     this.$swal({
    //       type: "error",
    //       text: "An error occurred. Please try again!",
    //     });
    //   }
    // },

    setChatShow() {
      this.isConnected = true;
      this.whatsappRegistered = true;
    },
  },
};
</script>
<style scoped>
.credit-img {
  max-width: 1.5rem;
  height: auto;
}

.custom-color {
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
}

.fb-button {
  color: white;
}

.intframe {
  height: 100vh;
  width: 100%;
}
</style>

<template>
    <div v-if="message.messageType === 'document' && message.fileLink">
        <div v-if="message.senderType === 'contact'" class="d-flex align-end justify-start mx-2 my-2">
            <div class="small-chat-img mr-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
            <div class="chat-recv-msg bg-white">
                <div class="message-container">
                    <v-icon large>{{ getFileIcon(message.fileLink) }}</v-icon>
                    <div class="file-name">{{ getFileName(message.fileLink) }}</div>
                    <v-btn icon :href="message.fileLink" target="_blank" download>
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </div>
                <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['message', 'formattedTime'],
    methods: {
        getFileIcon(filePath) {
            const extension = filePath.split('.').pop().toLowerCase();
            switch (extension) {
                case 'pdf': return 'mdi-file-pdf';
                case 'xlsx': case 'xls': return 'mdi-file-excel';
                case 'docx': case 'doc': return 'mdi-file-word';
                case 'csv': return 'mdi-file-table';
                case 'jpg': case 'jpeg': case 'png': case 'gif': return 'mdi-file-image';
                default: return 'mdi-file';
            }
        },
        getFileName(filePath) {
            return filePath.split('/').pop();
        }
    }
};
</script>
<style scoped>
.bg-white {
    background-color: white;
}

.bg-blue {
    background-color: #dcf8c6;
    color: black;
}

.compressed {
    width: 100%;
    max-width: 10rem;
    object-fit: cover;
    height: auto;
    z-index: 0;
}

.chat-recv-msg {

    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 8px;
    /* margin-left: 0.5rem; */
    max-width: 60%;
}

.file-name {
    font-size: 0.8rem;
    color: #333;
    overflow: hidden;
    /* Ensures overflow is handled by hiding */
    white-space: nowrap;
    /* Keeps the text on a single line */
    text-overflow: ellipsis;
    /* Adds an ellipsis when text overflows */
    max-width: 160px;
    /* Sets a maximum width for the file name; adjust as needed */
    display: block;
    /* Optional: Ensures the element behaves as a block for width to work */
}

.image-container {
    overflow: hidden;
    cursor: grab;
    width: 100%;
    height: auto;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Adjust based on your dialog size */
}

.message-container {
    width: 100%;
    min-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    /* Adjust size as needed */
    height: 48px;
    /* Adjust size as needed */
    background: url('../../assets/Vector.svg') no-repeat center center;
    /* Use a play icon image */
    background-size: cover;
    cursor: pointer;
    z-index: 2;
    color: black;
    transition: background-image 0.3s ease;
}

.play-button:hover {
    background-image: url('../../assets/vector1.svg');
}


.small-chat-img {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* margin-left: 0.2rem; */
}

.small-chat-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.text-blue {
    color: #1877F2;
    font-size: 0.7rem;
}

.text-grey {
    color: #808080;
    font-size: 0.7rem;
}

.text-red {
    color: #ff3c3c;
    font-size: 0.7rem;
}

.ta-r {
    text-align: right;

}

.time {
    font-size: 0.6rem;
}

.video-thumbnail {
    position: relative;
    width: 100%;
    height: auto;
    /* padding-top: 56.25%; */
    /* 16:9 Aspect Ratio */
    background-size: cover;
    background-position: center;
}
</style>
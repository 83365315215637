<template>
    <div v-if="message.messageType == 'template'">
        <!-- Received Message -->
        <div v-if="message.senderType == 'contact'" class="d-flex align-end justify-start mx-2 my-2">
            <div class="small-chat-img mr-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
            <div class="chat-recv-msg bg-white">
                <p class="font-weight-black my-1 mx-0 subtitle-1">{{ message.data[0].text }}
                </p>
                <p class="my-1 mx-0 subtitle-1">{{ message.data[1].text }}</p>
                <p class="font-weight-thin caption my-1 mx-0 subtitle-1">{{
                    message.data[2].text }}</p>
                <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
            </div>
        </div>
        <!-- Sent message  -->
        <div v-if="message.senderType == 'user'" class="d-flex align-end justify-end my-2">
            <div class="chat-recv-msg bg-blue">
                <!-- Header -->
                <div v-if="message.data.some(item => item.type === 'HEADER') && message.data.find(item => item.type === 'HEADER').format.toLowerCase() === 'image'"
                    class="image-box">
                    <img :src="message.data.find(item => item.type === 'HEADER').example.header_handle[0]" alt="">
                </div>
                <p class="font-weight-black my-1 mx-0 subtitle-1">{{ message.data.some(item => item.type === 'HEADER')
                    && message.data.find(item => item.type === 'HEADER').text }}
                </p>
                <!-- Body -->
                <p class="my-1 mx-0">{{ message.messageVariables ? formatString(message.data.some(item => item.type ===
                    'BODY') && message.data.find(item => item.type === 'BODY').text,
                    message.messageVariables) : message.data.some(item => item.type === 'BODY') &&
                message.data.find(item => item.type === 'BODY').text }}</p>
                <!-- Footer -->
                <p class="font-weight-thin my-1 mx-0 caption">{{ message.data.some(item => item.type === 'FOOTER') ?
                    message.data.find(item => item.type === 'FOOTER').text : '' }}</p>
                <!-- Buttons -->
                <div v-if="message.data.some((item) => item.type === 'BUTTONS')">
                    <v-btn v-for="(item, index) in message.data.find(item => item.type === 'BUTTONS').buttons"
                        :key="index" class="c-blue my-2" block small>{{ item.text
                        }}</v-btn>
                </div>
                <div class="d-flex justify-end align-center">
                    <div class="time mr-1">{{ formattedTime(message.sentAt) }}</div>
                    <!-- <v-icon :class="iconColor(message.status)">{{ status(message.status)
                        }}</v-icon> -->
                    <template v-if="message.status === 'failed' && message.error">
                        <v-tooltip top color="#E53935" max-width="250">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon :class="iconColor(message.status)" v-bind="attrs" v-on="on">
                                    {{ status(message.status) }}
                                </v-icon>
                            </template>
                            <span class="custom-tooltip">{{ message.error[0].title }}</span>
                        </v-tooltip>
                    </template>
                    <v-icon v-else :class="iconColor(message.status)">
                        {{ status(message.status) }}
                    </v-icon>
                </div>
            </div>
            <div class="small-chat-img mx-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {},
    props: ["message", "iconColor", "status", "formattedTime"],
    methods: {
        formatString(message, varArray) {
            message = message.replace(/\{\{(\d+)\}\}/g, (match, number) => {
                // Adjust for zero-based index
                let index = parseInt(number, 10) - 1;
                // Check if there's a corresponding element in the array
                if (index >= 0 && index < varArray.length) {
                    return varArray[index];
                }
                // Return the original match if no element is found
                return match;
            });
            return message;
        }
    }
}
</script>
<style scoped>
.bg-white {
    background-color: white;
}

.bg-blue {
    background-color: #dcf8c6;
    color: black;
}

.chat-recv-msg {

    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 8px;
    /* margin-left: 0.5rem; */
    max-width: 60%;
}


.custom-tooltip {
    font-size: 0.7rem;
    text-wrap: wrap;
    width: 10rem;
}

.c-blue {
    color: #5890FF;
    text-transform: capitalize;
}

.image-box {
    width: 100%;
}

.image-box img {
    width: 100%;
    object-fit: cover;
}


.small-chat-img {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* margin-left: 0.2rem; */
}

.small-chat-img img {
    width: 100%;
    /* Ensures the image fills the container horizontally */
    height: 100%;
    /* Ensures the image fills the container vertically */
    object-fit: cover;
    /* Scales the image while preserving aspect ratio */
    object-position: center;
    /* Centers the image within the container */
    position: absolute;
    /* Allows the image to be positioned within the container */
    top: 0;
    /* Aligns the top of the image with the top of the container */
    left: 0;
    /* Aligns the left of the image with the left of the container */
}


.ta-r {
    text-align: right;
}

.text-blue {
    color: #1877F2;
    font-size: 0.7rem;
}

.text-grey {
    color: #808080;
    font-size: 0.7rem;
}

.text-red {
    color: #ff3c3c;
    font-size: 0.7rem;
}


.time {
    font-size: 0.6rem;
}
</style>
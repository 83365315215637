<template>
  <div v-if="message.messageType == 'contacts'">
    <!-- * received message  -->
    <div
      v-if="message.senderType == 'contact'"
      class="d-flex align-end justify-start mx-2 my-2"
    >
      <div class="small-chat-img mr-2">
        <img
          src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
          alt="user"
        />
      </div>
      <div class="chat-recv-msg bg-white" @click="openContactDialog">
        <!-- {{ message.content }} -->
        <div class="d-flex flex-column align-center justify-space-between">
          <div class="small-chat-img">
            <img
              src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
              alt="user"
            />
          </div>
          <p class="font-weight-bold mt-2">
            {{
              message.data[0].name
                ? message.data[0].name.formatted_name
                : message.data[0].phones[0].phone
            }}
          </p>
        </div>
        <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
      </div>
      <!-- * Dialog to show all the numbers -->
      <v-dialog v-model="contactDialog" max-width="500">
        <v-card>
          <v-card-title>
            {{ message.data[0].name.formatted_name }}
          </v-card-title>
          <v-card-text
            v-for="item in message.data[0].phones"
            :key="item.phone"
            class="d-flex align-center justify-space-between"
          >
            <div>
              {{ item.phone }}
            </div>
            <v-btn
              class="primarygrad"
              @click="
                openSaveDialog(item.phone, message.data[0].name.formatted_name)
              "
            >
              <v-icon light>
                mdi-content-save-outline
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- * Dialog to save the number to the list -->
      <v-dialog
        v-model="saveDialog"
        transition="dialog-top-transition"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title class="primarygrad">Contact</v-card-title>
          <div class="pa-3">
            <v-select
              v-model="selectedList"
              :items="lists"
              item-text="name"
              item-value="id"
              label="List"
              return-object
            ></v-select>
            <v-text-field v-model="contactName" label="Name"></v-text-field>
            <vue-tel-input
              v-model="selectedContact"
              :valid-characters-only="true"
              class="secondary--text"
              label="Contact Number"
              @input="getPhoneNumber"
            ></vue-tel-input>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeSaveDialog">Cancel</v-btn>
            <v-btn color="primarygrad" @click="saveContact">Send</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Sent message  -->
    <!-- <div v-if="message.senderType == 'user'" class="d-flex align-end justify-end my-2">
            <div class="chat-recv-msg bg-blue">
                {{ message.content }}
                <div class="d-flex justify-end align-center min-width mt-2">
                    <div class="time mr-1">{{ formattedTime(message.sentAt) }}</div>
                    <v-icon :class="iconColor(message.status)">{{ status(message.status)
                        }}</v-icon>
                </div>
            </div>
            <div class="small-chat-img mx-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
        </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["message", "iconColor", "status", "formattedTime"],
  data() {
    return {
      contactDialog: false,
      lists: null,
      saveDialog: false,
      selectedContact: "",
      contactName: "",
      selectedList: null,
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "companyDetails", "selectedTeam"]),
  },
  methods: {
    openContactDialog() {
      this.contactDialog = true;
    },
    async openSaveDialog(number, name) {
      await this.fetchListByTeam();
      this.saveDialog = true;
      this.contactDialog = false;
      this.selectedContact = number.replace(/\s+/g, "");

      this.contactName = name;
    },
    closeSaveDialog() {
      this.saveDialog = false;
      this.selectedContact = "";
      this.contactName = "";
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },

    async fetchListByTeam() {
      const self = this;
      try {
        var teamsId = self.getTeamIds();
        var response = await self.$http.post(`${self.ENDPOINT}/lists/fetch`, {
          teams: teamsId,
        });
        self.lists = response.body;
      } catch (e) {
        console.log(e);
      }
    },
    // Get the number with country code
    getPhoneNumber(e, phone) {
      if (e) {
        return (this.selectedContact = phone.number.e164);
      } else {
        return (this.selectedContact = "");
      }
    },
    async saveContact() {
      const self = this;
      if (this.selectedList && this.selectedContact.length) {
        try {
          const data = {
            name: this.contactName,
            phone: this.selectedContact,
            listId: this.selectedList.id,
            companyId: this.user.companyId,
          };

          const postContact = await self.$http.post(
            `${this.ENDPOINT}/contacts`,
            data
          );

          if (postContact.body) {
            this.closeSaveDialog();
            this.$swal({
              type: "success",
              text: "Contact successfully saved",
            });
          }
        } catch (error) {
          console.log("Error saving contact: ", error);
          this.closeSaveDialog();
          this.$swal({
            type: "error",
            text: "An error occured while saving contact",
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: #dcf8c6;
  color: black;
}

.contact-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.chat-recv-msg {
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 8px;
  /* margin-left: 0.5rem; */
  max-width: 60%;
}

.min-width {
  min-width: 4rem;
}

.small-chat-img {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  /* margin-left: 0.2rem; */
}

.small-chat-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.text-blue {
  color: #1877f2;
  font-size: 0.7rem;
}

.text-grey {
  color: #808080;
  font-size: 0.7rem;
}

.text-red {
  color: #ff3c3c;
  font-size: 0.7rem;
}

.ta-r {
  text-align: right;
}

.time {
  font-size: 0.6rem;
}
</style>

<template>
  <div v-if="message.messageType === 'audio' && message.fileLink">
    <div
      v-if="message.senderType === 'contact'"
      class="d-flex align-end justify-start mx-2 my-2"
    >
      <div class="small-chat-img mr-2 imgUser">
        <img
          src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
          alt="user"
        />
      </div>
      <div class="chat-recv-msg bg-white">
        <div class="message-container">
          <v-btn icon @click="togglePlay">
            <v-icon>
              {{ isPlaying ? "mdi-pause" : "mdi-play" }}
            </v-icon>
          </v-btn>
          <v-progress-linear
            :value="progress"
            color="blue"
            striped
            height="10"
          ></v-progress-linear>
          <span class="ml-2">{{ timeLeft }}</span>
        </div>
        <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "formattedTime"],
  data() {
    return {
      isPlaying: false,
      progress: 0,
      timeLeft: "",
      audio: null,
    };
  },
  mounted() {
    this.audio = new Audio(this.message.fileLink);
    this.audio.addEventListener("timeupdate", this.updateProgress);
    this.audio.addEventListener("loadedmetadata", () => {
      this.calculateTimeLeft(this.audio.duration);
    });
    this.audio.addEventListener("ended", this.resetAudio);
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener("timeupdate", this.updateProgress);
      this.audio.removeEventListener("loadedmetadata", this.calculateTimeLeft);
      this.audio.removeEventListener("ended", this.resetAudio);
    }
  },
  methods: {
    togglePlay() {
      if (this.audio.paused) {
        this.audio.play();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    },
    updateProgress() {
      const currentTime = this.audio.currentTime;
      const duration = this.audio.duration;
      this.progress = (currentTime / duration) * 100;
      this.calculateTimeLeft(duration - currentTime);
    },
    calculateTimeLeft(timeLeft) {
      const mins = Math.floor(timeLeft / 60);
      const secs = Math.floor(timeLeft % 60);
      this.timeLeft = `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    },
    resetAudio() {
      this.audio.currentTime = 0;
      this.isPlaying = false;
      this.progress = 0;
      this.calculateTimeLeft(this.audio.duration);
    },
  },
};
</script>

<style scoped>
.audio-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.audio-controls button {
  padding: 5px 10px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.audio-controls button:hover {
  background-color: #0056b3;
}

.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: #dcf8c6;
  color: black;
}

.compressed {
  width: 100%;
  max-width: 10rem;
  object-fit: cover;
  height: auto;
  z-index: 0;
}

.chat-recv-msg {
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 8px;
  /* margin-left: 0.5rem; */
  max-width: 60%;
}

.image-container {
  overflow: hidden;
  cursor: grab;
  width: 100%;
  height: auto;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Adjust based on your dialog size */
}

.message-container {
  width: 100%;
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  /* Adjust size as needed */
  height: 48px;
  /* Adjust size as needed */
  background: url("../../assets/Vector.svg") no-repeat center center;
  /* Use a play icon image */
  background-size: cover;
  cursor: pointer;
  z-index: 2;
  color: black;
  transition: background-image 0.3s ease;
}

.play-button:hover {
  background-image: url("../../assets/vector1.svg");
}

.small-chat-img {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  /* margin-left: 0.2rem; */
}

.small-chat-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.text-blue {
  color: #1877f2;
  font-size: 0.7rem;
}

.text-grey {
  color: #808080;
  font-size: 0.7rem;
}

.text-red {
  color: #ff3c3c;
  font-size: 0.7rem;
}

.ta-r {
  text-align: right;
}

.time {
  font-size: 0.6rem;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  height: auto;
  /* padding-top: 56.25%; */
  /* 16:9 Aspect Ratio */
  background-size: cover;
  background-position: center;
}
</style>

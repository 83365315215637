<template>
    <div v-if="message.messageType == 'image' && message.fileLink">
        <!-- received message  -->
        <div v-if="message.senderType == 'contact'" class="d-flex align-end justify-start mx-2 my-2">
            <div class="small-chat-img mr-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
            <div class="chat-recv-msg bg-white">
                <div class="message-container" @click="openDialog">
                    <img class="compressed" :src="message.fileLink" alt="image" loading="lazy">
                    <div v-if="message.data[0].caption">{{ message.data[0].caption }}</div>
                </div>
                <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
                <!-- Dialog for image zoom -->
                <v-dialog v-model="dialog" width="auto" @click="closeDialog">
                    <div class="image-container" @wheel="handleScroll">
                        <img :src="message.fileLink" :style="imageStyle" draggable="false" @mousedown="startPan"
                            @mouseup="stopPan" @mouseleave="stopPan" @mousemove="panImage" />
                    </div>
                </v-dialog>
            </div>

        </div>
        <!-- Sent message  -->
        <!-- <div v-if="message.senderType == 'user'" class="d-flex align-end justify-end my-2">
            <div class="chat-recv-msg bg-blue">
                {{ message.content }}
                <div class="d-flex justify-end align-center">
                    <div class="time mr-1">{{ formattedTime(message.sentAt) }}</div>
                    <v-icon :class="iconColor(message.status)">{{ status(message.status)
                        }}</v-icon>
                </div>
            </div>
            <div class="small-chat-img mx-2">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                    alt="user">
            </div>
        </div> -->
    </div>
</template>
<script>

export default {
    components: {},
    props: ["message", "iconColor", "status", "formattedTime"],
    data() {
        return {
            dialog: false,
            scale: 1,
            startX: 0,
            startY: 0,
            moveX: 0,
            moveY: 0,
            panning: false,
        }
    },
    computed: {
        imageStyle() {
            return {
                transform: `scale(${this.scale}) translate(${this.moveX}px, ${this.moveY}px)`,
                cursor: this.panning ? 'grabbing' : 'grab',
                transition: 'transform 0.1s ease',
            };
        },
    },
    mounted() {

    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        handleScroll(event) {
            const zoomIntensity = 0.1;
            const direction = event.deltaY > 0 ? -1 : 1;
            const newScale = this.scale + direction * zoomIntensity;
            if (newScale >= 0.5 && newScale <= 3) {
                this.scale = newScale;
            }
            event.preventDefault();
        },
        startPan(event) {
            this.panning = true;
            this.startX = event.clientX - this.moveX;
            this.startY = event.clientY - this.moveY;
        },
        panImage(event) {
            if (!this.panning) return;
            this.moveX = event.clientX - this.startX;
            this.moveY = event.clientY - this.startY;
        },
        stopPan() {
            this.panning = false;
        },
    }
}
</script>
<style scoped>
.compressed {
    width: 100%;
    max-width: 10rem;
    object-fit: cover;
    height: auto;
}

.bg-white {
    background-color: white;
}

.bg-blue {
    background-color: #dcf8c6;
    color: black;
}

.chat-recv-msg {

    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 8px;
    /* margin-left: 0.5rem; */
    max-width: 60%;
}

.image-container {
    overflow: hidden;
    cursor: grab;
    width: 100%;
    height: auto;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Adjust based on your dialog size */
}

.message-container {
    width: 100%;

}


.small-chat-img {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* margin-left: 0.2rem; */
}

.small-chat-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.text-blue {
    color: #1877F2;
    font-size: 0.7rem;
}

.text-grey {
    color: #808080;
    font-size: 0.7rem;
}

.text-red {
    color: #ff3c3c;
    font-size: 0.7rem;
}

.ta-r {
    text-align: right;

}

.time {
    font-size: 0.6rem;
}
</style>